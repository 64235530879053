import { Node } from '@netvision/lib-widget-renderer';
import { Operation, deepClone, applyOperation } from 'fast-json-patch';

export type JsonPatch = Operation[];

export const merge = (widgetTree: Node, diff: JsonPatch): Node => {
  const res = deepClone(widgetTree);
  const errors: any = [];
  diff.forEach((op, index) => {
    try {
      applyOperation(res, op, undefined, undefined, undefined, index);
    } catch (e) {
      errors.push(op);
    }
  });
  if (errors.length > 0) {
    console.error('Skipped operations', errors);
  }
  return res as Node;
};
